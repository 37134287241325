
import { AuthLogin, AuthLayout, UserType } from '@bd/components'
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'Login',
  components: { AuthLogin, AuthLayout },
  setup() {
    const router = useRouter()

    const onLogin = async () => {
      await router.push({ path: '/' })
    }

    return { onLogin, UserType }
  },
})
