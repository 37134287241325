<template>
  <AuthLayout :bgImage="require('@bd/assets/images/image-woman.jpg')">
    <template #logo>
      <div class="text-center mt-auto">
        <img :src="require('@bd/assets/images/immoby-logo.svg')" />
      </div>
    </template>
    <template #main>
      <AuthLogin @login="onLogin" :userType="UserType.Agent" />
    </template>
  </AuthLayout>
</template>

<script lang="ts">
import { AuthLogin, AuthLayout, UserType } from '@bd/components'
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'Login',
  components: { AuthLogin, AuthLayout },
  setup() {
    const router = useRouter()

    const onLogin = async () => {
      await router.push({ path: '/' })
    }

    return { onLogin, UserType }
  },
})
</script>

<style lang="scss" scoped></style>
